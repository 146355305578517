const ENV = {
  LCL: {
    REACT_APP_API_SERVER: 'https://stg-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://stg-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-stg/o/',
    SCHOOL_ID_NO_2: '1075',
    SCHOOL_ID_NO_1: '1075',
    USER_ID_NO_2: [
      '0915004',
      '20000952',
      '22004861'
    ],
    USER_ID_NO_1: [
      '0915004',
      '20000952',
      '22004861'
    ]
  },

  DEV: {
    REACT_APP_API_SERVER: 'https://dev-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://dev-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-dev/o/'
  },

  STG: {
    REACT_APP_API_SERVER: 'https://stg-edu-gakutore-api.bappartners.com',
    SCORING_DOMAIN: 'https://stg-edu-auto-marking-web.bappartners.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/nrzfrynccg3o/b/edu-pdf-tool-stg/o/',
    SCHOOL_ID_NO_2: '1075',
    SCHOOL_ID_NO_1: '1075',
    USER_ID_NO_2: [
      '0915004',
      '20000952',
      '22004861',
      '00000011',
      '00000012',
      '00000013',
      '00000110',
      '00001011',
      '00001012',
      '00001013',
      '00001014',
      '00001015',
      '00001016',
      '00001017',
      '00001018',
      '00001019',
      '00001020',
      '00001021',
      '00001022',
      '00001023',
      '00001024',
      '00001025',
      '00001026',
      '00001027',
      '00001028',
      '00001031',
      '00001033',
      '00001034',
      '00001035',
      '00001036',
      '00001037',
      '00001038',
      '00001039',
      '00001040',
      '00001041',
      '00001042',
      '00001043',
      '00001044',
      '00001045',
      '00001046',
      '00001047',
      '00001048',
      '00001049',
      '00001050',
      '00001051',
      '00001052',
      '00001053',
      '00001054',
      '00001055',
      '00001056',
      '00001057',
      '00001059',
      '00001061',
      '00001062',
      '00001063',
      '00001067',
      '00001068',
      '00001069',
      '00001088',
      '00001089',
      '00001099',
      '00001101',
      '00001103',
      '00001120',
      '00001149'
    ],
    USER_ID_NO_1: [
      '0915004',
      '20000952',
      '22004861'
    ]
  },

  PRO: {
    REACT_APP_API_SERVER: 'https://ai-api.atwill-net.com',
    SCORING_DOMAIN: 'https://ai-scan.atwill-net.com/',
    ORACLE_DOMAIN: 'https://objectstorage.ap-tokyo-1.oraclecloud.com/n/sanarucloud/b/ai_gakutore/o/',
    SCHOOL_ID_NO_2: '7001',
    SCHOOL_ID_NO_1: '7001',
    USER_ID_NO_2: [
      '0003',
      '0010',
      '0004',
      '00000011',
      '00000012',
      '00000013',
      '00000110',
      '00001011',
      '00001012',
      '00001013',
      '00001014',
      '00001015',
      '00001016',
      '00001017',
      '00001018',
      '00001019',
      '00001020',
      '00001021',
      '00001022',
      '00001023',
      '00001024',
      '00001025',
      '00001026',
      '00001027',
      '00001028',
      '00001031',
      '00001033',
      '00001034',
      '00001035',
      '00001036',
      '00001037',
      '00001038',
      '00001039',
      '00001040',
      '00001041',
      '00001042',
      '00001043',
      '00001044',
      '00001045',
      '00001046',
      '00001047',
      '00001048',
      '00001049',
      '00001050',
      '00001051',
      '00001052',
      '00001053',
      '00001054',
      '00001055',
      '00001056',
      '00001057',
      '00001059',
      '00001061',
      '00001062',
      '00001063',
      '00001067',
      '00001068',
      '00001069',
      '00001088',
      '00001089',
      '00001099',
      '00001101',
      '00001103',
      '00001120',
      '00001149'
    ],
    USER_ID_NO_1: [
      '0003',
      '0010',
      '0004',
      '00000105'
    ]
  }
};

const config = ENV[process.env.REACT_APP_STAGE || 'LCL'];

const getEnv = (name, defaultValue) =>
  process.env[name] || config[name] || defaultValue;

export { getEnv };
